import React, { FunctionComponent, useMemo } from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout/layout'
import { Site, MarkdownRemark } from '../graphql-types'
import classnames from 'classnames'
import { GiTalk } from '../components/gitalk'
import { animated, useSpring } from 'react-spring'

const Styles = require('../styles/templates/post-detail.module.styl')

type Props = {
  data: {
    markdownRemark: MarkdownRemark
    site: Site
  }
  pageContext: {
    previous: null | MarkdownRemark
    next: null | MarkdownRemark
    prePath: string | null
    nextPath: string | null
  }
  location: Location
}

const isReadme = (slug?: string | null) => {
  return !!slug?.includes('readme')
}

const BlogPostTemplate: FunctionComponent<Props> = props => {
  const post = props.data.markdownRemark
  const { previous, next, prePath, nextPath } = props.pageContext // 由 createPage context 传递过来的数据
  const spring = useSpring({ opacity: 1, transform: 'translate3d(0,0,0)', from: { opacity: 0, transform: 'translate3d(0, -50px, 0)' } })

  const renderBottom = () => {
    const showPrevious = previous && !isReadme(previous.fields?.slug)
    const showNext = next && !isReadme(next.fields?.slug)
    return (
      <div className={classnames(Styles['bottom'], { [Styles['havetwo']]:  showPrevious && showNext })}>
        {showPrevious && (
          <Link className={Styles['pre']} to={prePath!} rel='prev'>
            <i className={classnames(Styles['icon'], 'iconfont', Styles['left'])}>&#xe65d;</i>{' '}
            <span className={Styles['title']}>{previous.frontmatter?.title}</span>
          </Link>
        )}
        {showNext && (
          <Link className={Styles['next']} to={nextPath!} rel='next'>
            <span className={Styles['title']}>{next.frontmatter?.title}</span>
            <i className={classnames('iconfont', Styles['icon'])}>&#xe65d;</i>
          </Link>
        )}
      </div>
    )
  }


  return (
    <Layout layoutCls={Styles['layout']}>
      <animated.div style={spring}>
        <article className={Styles['article']}>
          <header>
            <h1 className={Styles['title']}>{post.frontmatter?.title}</h1>
            <p className={Styles['date']}>{post.frontmatter?.date}</p>
          </header>
          <section className='post-detail-container' dangerouslySetInnerHTML={{ __html: post.html! }} />
        </article>
        { renderBottom() }
        <GiTalk postId={new Date(post.frontmatter?.date).getTime().toString()} />
      </animated.div>
    </Layout>
  )
}

export default BlogPostTemplate
// 可以接受 createPage 中的 context 作为参数
// $slug 为 context 中的 slug
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
