import React, { FunctionComponent, useEffect } from 'react'
import GitTalk from 'gitalk'
import 'gitalk/dist/gitalk.css'

type Props = {
  postId: string
}

export const GiTalk: FunctionComponent<Props> = (props) => {

  useEffect(() => {
    const gitalk = new GitTalk({
      id: props.postId, // optional
      owner: 'MLuminary',
      repo: 'hutchins.github.io',
      clientID: '7337a7896083b307f117',
      clientSecret: '07a1d88f49a06d948e1a1a5c6f6818dd24456193',
      admin: ['MLuminary'],
      distractionFreeMode: false  // Facebook-like distraction free mode
    })
    // @ts-ignore
    gitalk.render('comments')
  }, [props.postId])

  return <div id='comments'></div>
}